define("discourse/plugins/RO buttons/discourse/connectors/after-topic-footer-main-buttons/custom-public-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      clickButton() {
        window.open("https://bugs.remobjects.com/__scripts/newbug.php?secret=39dj938hd020ef9hcosipmnd384&url=" + window.location, "_blank");
      },
      logDA() {
        window.open("https://bugs.remobjects.com/__scripts/newbug2.php?project=dataabstract&url=" + window.location, "_blank");
      },
      logFire() {
        window.open("https://bugs.remobjects.com/__scripts/newbug2.php?project=fire&url=" + window.location, "_blank");
      },
      logElements() {
        window.open("https://bugs.remobjects.com/__scripts/newbug2.php?project=elements&url=" + window.location, "_blank");
      },
      logHydra() {
        window.open("https://bugs.remobjects.com/__scripts/newbug2.php?project=hydra&url=" + window.location, "_blank");
      },
      logIssue() {
        window.open("https://bugs.remobjects.com/__scripts/newbug2.php?project=issues&url=" + window.location, "_blank");
      }
    }
  };
});