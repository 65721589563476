define("discourse/plugins/RO buttons/discourse/templates/connectors/after-topic-footer-main-buttons/custom-public-button", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if currentUser.can_see_topic_group_button}}
  <!--  <button
      class="btn topic-group-button"
      title="Log Bug"
      {{action "clickButton"}}>Log Bug</button>-->
  
  <button
      class="btn topic-group-button"
      title="Log elements"
      {{action "logElements"}}>Log Elements</button>
  
  <button
      class="btn topic-group-button"
      title="Log hydra"
      {{action "logHydra"}}>Log Hydra</button>
  
  
  <button
      class="btn topic-group-button"
      title="Log DataAbstract"
      {{action "logDA"}}>Log Data Abstract</button>
  
  
  <button
      class="btn topic-group-button"
      title="Log Fire"
      {{action "logFire"}}>Log Fire</button>
  
  <button
      class="btn topic-group-button"
      title="Log Issues"
      {{action "logIssue"}}>Log Issues</button>
  
  {{/if}}
  
  */
  {
    "id": "LjGo/8lX",
    "block": "[[[41,[30,0,[\"currentUser\",\"can_see_topic_group_button\"]],[[[3,\"  <button\\n    class=\\\"btn topic-group-button\\\"\\n    title=\\\"Log Bug\\\"\\n    {{action \\\"clickButton\\\"}}>Log Bug</button>\"],[1,\"\\n\\n\"],[11,\"button\"],[24,0,\"btn topic-group-button\"],[24,\"title\",\"Log elements\"],[4,[38,2],[[30,0],\"logElements\"],null],[12],[1,\"Log Elements\"],[13],[1,\"\\n\\n\"],[11,\"button\"],[24,0,\"btn topic-group-button\"],[24,\"title\",\"Log hydra\"],[4,[38,2],[[30,0],\"logHydra\"],null],[12],[1,\"Log Hydra\"],[13],[1,\"\\n\\n\\n\"],[11,\"button\"],[24,0,\"btn topic-group-button\"],[24,\"title\",\"Log DataAbstract\"],[4,[38,2],[[30,0],\"logDA\"],null],[12],[1,\"Log Data Abstract\"],[13],[1,\"\\n\\n\\n\"],[11,\"button\"],[24,0,\"btn topic-group-button\"],[24,\"title\",\"Log Fire\"],[4,[38,2],[[30,0],\"logFire\"],null],[12],[1,\"Log Fire\"],[13],[1,\"\\n\\n\"],[11,\"button\"],[24,0,\"btn topic-group-button\"],[24,\"title\",\"Log Issues\"],[4,[38,2],[[30,0],\"logIssue\"],null],[12],[1,\"Log Issues\"],[13],[1,\"\\n\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `currentUser` property path was used in the `discourse/plugins/RO buttons/discourse/templates/connectors/after-topic-footer-main-buttons/custom-public-button.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.currentUser}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"button\",\"action\"]]",
    "moduleName": "discourse/plugins/RO buttons/discourse/templates/connectors/after-topic-footer-main-buttons/custom-public-button.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});